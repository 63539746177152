import { render, staticRenderFns } from "./AchievementsDetail.vue?vue&type=template&id=136ca8bc&scoped=true&"
import script from "./AchievementsDetail.vue?vue&type=script&lang=js&"
export * from "./AchievementsDetail.vue?vue&type=script&lang=js&"
import style0 from "./AchievementsDetail.vue?vue&type=style&index=0&id=136ca8bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136ca8bc",
  null
  
)

export default component.exports